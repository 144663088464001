<template>
  <div>
    <a-row>
      <a-col :span="24">
        <div>
          <a-row class="align-items-center mb-4" style="margin: -30px; padding: 15px 30px 22px 30px; background: #fff;">
            <a-col :span="18">
              <h3 class="mb-0" style="font-size: 20px;">Push-notifications</h3>
            </a-col>
            <a-col :span="6" class="text-right" style="display: flex; justify-content: flex-end;">
              <a-button size="large" @click="createExpModal">
                Create experiment
              </a-button>
              <a-button type="primary" class="ml-4" size="large" @click="createPushModal">
                <a-icon type="plus" /> Create new
              </a-button>
            </a-col>
          </a-row>
          <a-card>
            <a-table
              :columns="columns"
              :data-source="notifications"
              :rowKey="(record, index) => index"
            >
<!--              <a slot="title" slot-scope="text">{{ text }}</a>-->
<!--              <span slot="customTitle"> Title</span>-->
<!--              <span slot="status" slot-scope="status">-->
<!--              </span>-->
              <template slot="imagePath" slot-scope="text, record">
                  <span>
<!--                    <a href="javascript:;" @click="() => editPushModal(record)"><a-avatar shape="square" :src="record.imagePath" /></a>-->
                    <a-avatar shape="square" :src="record.imagePath" />
                  </span>
              </template>
              <template slot="pushTitle" slot-scope="text, record">
                  <span>
                    <a href="javascript:;" @click="() => editPushModal(record)">{{ record.title }}</a>
                  </span>
              </template>
              <template slot="platform" slot-scope="text">
                <span style="font-size: 16px;">
                  <a-icon v-if="text.toLowerCase().indexOf('android') !== -1" type="android" class="text-success mx-2" />
                  <a-icon v-if="text.toLowerCase().indexOf('ios') !== -1" type="apple" class="text-primary mx-2" />
                  <a-icon v-if="text.toLowerCase().indexOf('web') !== -1" type="global" class="text-default mx-2" />
                </span>
              </template>
              <template slot="status" slot-scope="text, record">
                  <span>
                    <a-tag :key="record.status" :color="statusColor[record.status]">{{ statusTpl[record.status] }}</a-tag>
                  </span>
              </template>
              <template slot="operation" slot-scope="text, record">
                <div class="row-operations">
                  <span>
                    <a class="font-size-18 text-primary" href="javascript:;" @click="() => editPushModal(record)"><a-icon type="edit" /></a>
                  </span>
                  <span>
                    <a-popconfirm
                      v-if="notifications.length"
                      title="Sure to delete this notification?"
                      @confirm="() => onDelete(record.id)"
                    >
                      <a class="font-size-18 text-danger" href="javascript:;"><a-icon type="delete" /></a>
                    </a-popconfirm>
                  </span>
                </div>
              </template>
            </a-table>
          </a-card>
        </div>
      </a-col>
    </a-row>
    <a-modal
      v-model="addModal"
      title="New notification"
      @ok="handleCreatePush"
      :footer="null"
      class="notification-modal"
      width="1000px"
      :destroyOnClose="true"
    >
      <add-push-component @closeModalFunction="handleAddPush" @submitModalFunction="handleCreatePush" />
    </a-modal>
    <a-modal
      v-model="editModal"
      title="Edit notification"
      :footer="null"
      class="notification-modal"
      width="1000px"
      :destroyOnClose="true"
    >
      <edit-push-component @closeModalFunction="handleEditPush" @submitModalFunction="handleUpdatePush" :push-obj="editPushObj" />
    </a-modal>
    <a-modal
      v-model="addExpModal"
      title="Create Notification Experiment"
      @ok="handleCreateExp"
      :footer="null"
      class="notification-modal"
      width="1000px"
      :destroyOnClose="true"
    >
      <add-push-experiment @closeModalFunction="handleCloseExp" @submitModalFunction="handleCreateExp" />
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment'
import addPushComponent from '@/views/apps/push-notifications/addPush.vue'
import editPushComponent from '@/views/apps/push-notifications/editPush.vue'
import addPushExperiment from '@/views/apps/push-notifications/addExp.vue'
import apiClient from '@/services/axios'

const statusTpl = {
  0: 'Ожидается',
  1: 'Выполняется',
  2: 'Завершено',
  3: 'Отменено',
}
const statusColor = { 0: '', 1: 'blue', 2: 'green', 3: 'red' }

function formatDate(date) {
  return moment(date).format('DD.MM.YYYY HH:mm')
}
const columns = [
  {
    title: '',
    dataIndex: 'imagePath',
    width: '48px',
    key: 'imagePath',
    // slots: { title: 'customTitle' },
    scopedSlots: { customRender: 'imagePath' },
  },
  {
    title: 'Notification title',
    dataIndex: 'title',
    key: 'title',
    // slots: { title: 'customTitle' },
    scopedSlots: { customRender: 'pushTitle' },
  },
  {
    title: 'Platform',
    dataIndex: 'platform',
    key: 'platform',
    scopedSlots: { customRender: 'platform' },
  },
  {
    title: 'Start date',
    dataIndex: 'startsAt',
    key: 'startsAt',
    customRender: text => formatDate(text),
  },
  {
    title: 'End date',
    dataIndex: 'endsIn',
    key: 'endsIn',
    customRender: text => formatDate(text),
  },
  {
    title: 'Users number',
    dataIndex: 'pushSent',
    key: 'pushSent',
    customRender: text => '1 000 000 - 2 000 000',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    // customRender: text => statusTpl[text],
    scopedSlots: { customRender: 'status' },
  },
  {
    title: 'Actions',
    dataIndex: 'operation',
    key: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
  // {
  //   title: 'Sent',
  //   dataIndex: 'pushOpened',
  //   key: 'pushOpened',
  // },
]

export default {
  name: 'index',
  components: {
    addPushComponent, editPushComponent, addPushExperiment,
  },
  data() {
    return {
      notifications: [],
      columns,
      statusTpl,
      statusColor,
      editPushObj: undefined,
      addModal: false,
      editModal: false,
      addExpModal: false,
    }
  },
  created() {
    this.getNotifications()
  },
  methods: {
    createPushModal() {
      this.addModal = true
    },
    editPushModal(obj) {
      this.editPushObj = obj
      this.editModal = true
      console.log(obj)
    },
    createExpModal() {
      this.addExpModal = true
    },
    handleAddPush(e) {
      this.addModal = false
    },
    handleEditPush(e) {
      this.editModal = false
    },
    handleCloseExp(e) {
      this.addExpModal = false
    },
    handleCreatePush(e) {
      console.log('successfully created. updating notifications list...')
      this.addModal = false
      this.notifications.unshift(e)
      console.log(e)
    },
    handleUpdatePush(obj) {
      console.log('successfully updated. updating notifications list...')
      this.getNotifications()
      this.editModal = false
      console.log(obj)
      console.log(this.notifications)
    },
    handleCreateExp(e) {
      console.log('Experiment successfully created.')
      this.addExpModal = false
      // console.log(e)
    },
    getNotifications() {
      const url = '/admin/notifications'
      apiClient.get(url).then((response) => {
        this.notifications = response.data.data
        console.log(this.notifications)
      }).catch(error => { console.log(error) })
    },
    onDelete(key) {
      const target = this.notifications.filter(item => key === item.id)[0]
      const url = '/admin/notifications/' + key
      apiClient.delete(url).then((response) => {
        this.$notification.success({
          message: 'Notification "' + target.title + '" successfully deleted',
          description: '',
        })
        this.notifications.splice(this.notifications.findIndex(x => x.id === target.id), 1)
        // this.appData = dataSource.filter(item => item.application_id !== key)
        // this.cacheData = this.appData.map(item => ({ ...item }))
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while deleting notification',
          description: error.message,
        })
      })
    },
  },
}
</script>
<style>
  .ant-table-thead th {
    background-color: #FAFAFA !important;
    font-weight: 600 !important;
  }
  .ant-table-tbody td {
    color: #595959;
  }
</style>
<style scoped>
  button {
    border-radius: 2px !important;
  }
  .row-operations a {
    margin-right: 8px;
  }
</style>
